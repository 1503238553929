<script>
	import { page } from "$app/stores";

	$: ({ hostname } = $page);
</script>

<footer class="flex flex-shrink flex-col justify-end space-y-5 pt-20">
	<div
		class="flex flex-col-reverse md:flex-row md:items-center md:justify-around"
	>
		<a
			href="https://sites.google.com/txm-methods.com/centraldechamadostxm/início"
			rel="noopener noreferrer"
			target="_blank"
		>
			<button class="mt-10 flex justify-center space-x-4 md:mt-0">
				<div>
					<img alt="" src="/img/footer/icon_atention.svg" />
				</div>
				<p class="text-gray-brand-200">Relatar um problema</p>
			</button>
		</a>
		<div class="flex grow flex-row items-center justify-center">
			<div>
				<svg
					class="w-full text-gray-brand-200"
					fill="none"
					height="70"
					viewBox="0 0 145 70"
					width="145"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M42.4935 49.7085C42.6898 49.7085 42.849 49.5493 42.849 49.353C42.849 49.1566 42.6898 48.9974 42.4935 48.9974C42.2971 48.9974 42.1379 49.1566 42.1379 49.353C42.1379 49.5493 42.2971 49.7085 42.4935 49.7085Z"
						fill="currentColor"
					/>
					<path
						d="M84.697 0.355544C84.697 0.261251 84.7344 0.170802 84.8011 0.104126C84.8678 0.0374511 84.9583 0 85.0526 0C85.1469 0 85.2373 0.0374511 85.3039 0.104126C85.3706 0.170802 85.408 0.261251 85.408 0.355544C85.4083 0.425437 85.3872 0.493722 85.3474 0.551214C85.3153 0.599907 85.2715 0.63978 85.22 0.667217C85.1685 0.694654 85.1109 0.708803 85.0526 0.708329C85.006 0.708693 84.9598 0.699821 84.9167 0.682256C84.8736 0.66469 84.8344 0.638747 84.8013 0.605952C84.7683 0.573158 84.742 0.534142 84.7241 0.491161C84.7062 0.448179 84.697 0.402104 84.697 0.355544Z"
						fill="currentColor"
					/>
					<path
						d="M85.0031 0.716581C85.1995 0.716581 85.3586 0.557393 85.3586 0.361037C85.3586 0.164682 85.1995 0.00549316 85.0031 0.00549316C84.8068 0.00549316 84.6476 0.164682 84.6476 0.361037C84.6476 0.557393 84.8068 0.716581 85.0031 0.716581Z"
						fill="currentColor"
					/>
					<path
						d="M71.038 4.2196C71.2344 4.2196 71.3936 4.06041 71.3936 3.86406C71.3936 3.6677 71.2344 3.50854 71.038 3.50854C70.8417 3.50854 70.6825 3.6677 70.6825 3.86406C70.6825 4.06041 70.8417 4.2196 71.038 4.2196Z"
						fill="currentColor"
					/>
					<path
						d="M66.7055 10.4263C66.9019 10.4263 67.0611 10.2671 67.0611 10.0708C67.0611 9.8744 66.9019 9.71521 66.7055 9.71521C66.5092 9.71521 66.35 9.8744 66.35 10.0708C66.35 10.2671 66.5092 10.4263 66.7055 10.4263Z"
						fill="currentColor"
					/>
					<path
						d="M144.818 0.760673C144.837 0.377579 144.694 0.184638 144.283 0.192906C142.079 0.242516 139.86 0.101964 137.652 0.270084C135.748 0.576008 134.701 1.87139 133.852 3.4644C128.747 13.0307 123.505 22.5281 118.511 32.1275L118.484 32.1799L118.456 32.1275C113.46 22.5309 108.22 13.0335 103.113 3.4644C102.264 1.87139 101.217 0.576008 99.3127 0.270084C97.1078 0.101964 94.903 0.242516 92.6816 0.192906C92.2737 0.192906 92.1304 0.377579 92.1469 0.760673C92.1469 0.967379 92.1469 1.17684 92.1469 1.38355V48.4104C92.1469 49.6341 92.1469 49.6341 93.3844 49.6341H98.7807C100.032 49.6341 100.035 49.6341 100.035 48.4242C100.035 43.1288 100.035 37.8344 100.035 32.541C100.035 26.2755 100.035 20.0137 100.035 13.7556C100.031 13.534 100.055 13.3129 100.104 13.0969C100.174 13.2082 100.268 13.3024 100.379 13.3725C103.135 18.6182 105.901 23.8639 108.675 29.1096C110.748 33.0508 112.834 36.9865 114.86 40.9607C115.64 42.4848 116.75 43.4412 118.462 43.4632H118.517C120.226 43.4329 121.339 42.4848 122.116 40.9607C124.145 36.9975 126.228 33.0618 128.301 29.1096C131.057 23.8621 133.823 18.6163 136.6 13.3725C136.71 13.3017 136.804 13.2077 136.875 13.0969C136.924 13.3129 136.947 13.534 136.944 13.7556C136.944 20.0137 136.944 26.2755 136.944 32.541V48.4242C136.944 49.6313 136.944 49.6313 138.198 49.6341H143.597C144.832 49.6341 144.832 49.6341 144.832 48.4104V1.38079C144.818 1.17408 144.807 0.964623 144.818 0.760673Z"
						fill="currentColor"
					/>
					<path
						d="M69.3707 26.767C67.8163 28.9884 66.2674 31.2015 64.7819 33.3292C69.6877 40.3434 73.786 46.2055 75.062 48.0466C75.8888 49.2537 77.1318 49.7857 78.6063 49.7774C80.5934 49.7774 84.7661 49.7774 84.8681 49.7774C84.9315 49.7712 84.9922 49.7481 85.0436 49.7105C85.0951 49.6728 85.1354 49.622 85.1605 49.5634C85.1856 49.5048 85.1944 49.4405 85.1861 49.3773C85.1779 49.3141 85.1527 49.2543 85.1134 49.2042C83.581 47.0131 75.7124 35.8041 69.3707 26.767Z"
						fill="currentColor"
					/>
					<path
						d="M22.9308 28.8534C22.9308 35.4845 22.917 42.1156 22.9473 48.7495C22.9473 49.535 22.793 49.8188 21.9414 49.7885C20.0121 49.7196 18.0525 49.7224 16.1095 49.7885C15.2827 49.8161 15.076 49.5625 15.0787 48.7495C15.1044 35.5571 15.109 22.3638 15.0925 9.16952C15.0925 7.65644 15.2661 7.84659 13.7641 7.84384C9.52798 7.84384 5.29194 7.81078 1.05861 7.86591C0.160134 7.86591 -0.0273005 7.60683 0.00301632 6.76348C0.069162 4.85627 0.0443574 2.94631 0.00301632 1.03635C0.00301632 0.435527 0.0967416 0.192992 0.780248 0.209529C12.907 0.227902 25.0245 0.227902 37.1328 0.209529C37.7612 0.209529 37.9596 0.361125 37.9596 1.01431C37.9155 2.99317 37.91 4.97201 37.9596 6.95087C37.9789 7.67572 37.786 7.87694 37.0501 7.87142C32.6762 7.83284 28.2996 7.87142 23.9257 7.83008C23.0824 7.83008 22.9115 8.07537 22.9142 8.86912C22.9473 15.5223 22.9308 22.1892 22.9308 28.8534Z"
						fill="currentColor"
					/>
					<path
						d="M84.8211 1.24027C84.8955 1.13002 85.0196 0.939864 85.1298 0.768988C85.1632 0.715166 85.1818 0.653482 85.1838 0.590173C85.1859 0.526864 85.1712 0.464147 85.1413 0.408296C85.1114 0.352445 85.0674 0.30545 85.0136 0.272007C84.9598 0.238563 84.8982 0.219858 84.8349 0.217773C84.7384 0.217773 80.585 0.217773 78.5979 0.217773C77.1234 0.217773 75.8942 0.741404 75.0564 1.95132C72.0936 6.21772 69.2135 10.542 66.2369 14.8084C66.2369 14.8084 44.7395 45.4256 42.0937 49.2235C42.0544 49.2736 42.0293 49.3335 42.021 49.3967C42.0127 49.4599 42.0215 49.5241 42.0466 49.5828C42.0717 49.6414 42.112 49.6922 42.1635 49.7298C42.2149 49.7675 42.2756 49.7906 42.339 49.7968C42.441 49.7968 46.6137 49.7747 48.6008 49.7968C50.0753 49.7968 51.3072 49.2731 52.1451 48.0659C55.6701 42.9837 80.5767 7.44143 84.8211 1.24027Z"
						fill="currentColor"
					/>
					<path
						d="M42.3996 1.24021C44.4033 4.16992 51.0206 13.6425 57.8116 23.3632L62.3976 16.8313L60.97 14.8001C58.0072 10.5337 55.1271 6.20943 52.1644 1.94303C51.3375 0.73311 50.0973 0.201177 48.6228 0.209445C46.6357 0.209445 42.4795 0.209445 42.3858 0.209445C42.3225 0.21153 42.2608 0.230235 42.207 0.263679C42.1532 0.297122 42.1092 0.344117 42.0793 0.399968C42.0495 0.455819 42.0348 0.518569 42.0368 0.581879C42.0388 0.645188 42.0575 0.706838 42.0909 0.76066C42.2011 0.939805 42.3252 1.12997 42.3996 1.24021Z"
						fill="currentColor"
					/>
					<path
						d="M84.4711 0.570469C84.4716 0.500934 84.4927 0.433095 84.5317 0.375505C84.5707 0.317916 84.6258 0.273144 84.6901 0.246786C84.7544 0.220427 84.8252 0.213674 84.8934 0.227373C84.9615 0.241073 85.0241 0.274592 85.0733 0.323762C85.1225 0.372932 85.156 0.435546 85.1697 0.503721C85.1834 0.571895 85.1766 0.642579 85.1503 0.706927C85.1239 0.771275 85.0791 0.826416 85.0216 0.865388C84.964 0.90436 84.8962 0.925434 84.8266 0.92598C84.7798 0.926347 84.7334 0.917408 84.6901 0.89967C84.6468 0.881933 84.6075 0.855748 84.5744 0.822661C84.5413 0.789573 84.5151 0.750228 84.4974 0.706927C84.4797 0.663626 84.4707 0.617261 84.4711 0.570469Z"
						fill="currentColor"
					/>
					<path
						d="M66.5264 9.90253L65.9752 9.88598C64.4539 9.88598 61.458 9.8667 57.5995 9.8667C58.4649 11.1382 59.3349 12.4069 60.2095 13.6728H64.5806C65.6528 12.1294 66.7855 10.5061 66.8241 10.4537C66.8567 10.3996 66.8746 10.3378 66.8761 10.2746C66.8776 10.2114 66.8626 10.1488 66.8325 10.0932C66.8025 10.0375 66.7584 9.9907 66.7048 9.95727C66.6511 9.92383 66.5896 9.90496 66.5264 9.90253Z"
						fill="currentColor"
					/>
					<path
						d="M70.8534 3.71515L70.2884 3.69863C67.7666 3.67658 61.1934 3.67381 53.3606 3.67932C54.2315 4.94711 55.0997 6.21584 55.9651 7.48547C61.2815 7.48547 65.8869 7.48547 68.891 7.48547C69.9631 5.94207 71.0959 4.31873 71.1373 4.26361C71.1696 4.21063 71.1877 4.1502 71.1899 4.08816C71.1921 4.02611 71.1783 3.96458 71.1498 3.90944C71.1212 3.85431 71.079 3.80745 71.027 3.77342C70.9751 3.73939 70.9153 3.71932 70.8534 3.71515Z"
						fill="currentColor"
					/>
					<path
						d="M66.9562 36.438H62.6127C61.5406 37.9814 60.4078 39.6047 60.3665 39.6571C60.3337 39.7096 60.3149 39.7696 60.3119 39.8314C60.309 39.8932 60.322 39.9547 60.3497 40.01C60.3773 40.0654 60.4188 40.1127 60.47 40.1474C60.5212 40.1821 60.5805 40.2031 60.6421 40.2083C60.8461 40.2083 61.0721 40.2248 61.1933 40.2248C62.6816 40.2248 65.8483 40.2441 69.5966 40.2469L66.9562 36.438Z"
						fill="currentColor"
					/>
					<path
						d="M71.2832 42.6282C66.1156 42.6282 61.2015 42.6282 58.2856 42.6282C57.2135 44.1716 56.0808 45.7949 56.0422 45.8472C56.0102 45.8999 55.992 45.9598 55.9894 46.0213C55.9869 46.0828 56.0001 46.144 56.0276 46.1991C56.0551 46.2541 56.0962 46.3013 56.147 46.3362C56.1977 46.3711 56.2565 46.3925 56.3178 46.3985L56.869 46.415C59.3495 46.4371 66.2231 46.4398 73.9208 46.4343C73.2483 45.4393 72.3443 44.1468 71.2832 42.6282Z"
						fill="currentColor"
					/>
					<path
						d="M60.5264 69.3179V60.5812L56.1911 69.3179H55.4084L51.0731 60.5812V69.3179H49.8687V57.3042H50.6955L55.8135 67.4768L60.9095 57.3042H61.7363V69.3179H60.5264Z"
						fill="currentColor"
					/>
					<path
						d="M67.4 69.3179V57.3042H74.1469V58.4259H68.6485V62.6978H72.8791V63.8002H68.6485V68.21H74.4225V69.3317L67.4 69.3179Z"
						fill="currentColor"
					/>
					<path
						d="M82.9029 58.4039V69.318H81.6516V58.4039H77.421V57.3015H87.1307V58.4039H82.9029Z"
						fill="currentColor"
					/>
					<path
						d="M99.2905 69.3179V63.8058H92.6925V69.3179H91.444V57.3042H92.6925V62.6978H99.2905V57.3042H100.539V69.3179H99.2905Z"
						fill="currentColor"
					/>
					<path
						d="M112.024 69.6762C108.303 69.6762 105.533 66.8209 105.533 63.3097C105.533 59.7984 108.289 56.9459 112.024 56.9459C115.758 56.9459 118.517 59.8012 118.517 63.3097C118.517 66.8182 115.747 69.6762 112.024 69.6762ZM112.024 58.0456C110.979 58.033 109.955 58.3313 109.08 58.9024C108.206 59.4736 107.521 60.2918 107.113 61.2532C106.704 62.2146 106.591 63.2757 106.787 64.3016C106.983 65.3274 107.48 66.2718 108.215 67.0147C108.949 67.7575 109.887 68.2652 110.911 68.4733C111.934 68.6814 112.997 68.5805 113.963 68.1834C114.929 67.7862 115.755 67.1108 116.336 66.243C116.918 65.3752 117.228 64.3542 117.227 63.3097C117.24 62.6201 117.114 61.9349 116.858 61.2946C116.601 60.6542 116.22 60.0716 115.735 59.5811C115.25 59.0906 114.672 58.702 114.034 58.4383C113.397 58.1746 112.713 58.0411 112.024 58.0456Z"
						fill="currentColor"
					/>
					<path
						d="M127.251 69.3179H123.508V57.3042H127.146C130.594 57.3042 133.893 59.4815 133.893 63.3538C133.893 67.226 130.677 69.3179 127.251 69.3179ZM127.209 58.4259H124.756V68.1549H127.314C130.233 68.1549 132.603 66.4185 132.603 63.3538C132.603 60.289 130.128 58.4259 127.209 58.4259Z"
						fill="currentColor"
					/>
					<path
						d="M141.232 69.6624C140.098 69.6438 138.986 69.3466 137.994 68.797V67.419C138.523 67.7359 139.962 68.5407 141.232 68.5407C142.627 68.5407 143.727 67.7993 143.727 66.5508C143.727 65.5779 142.732 64.7979 141.993 64.3101L140.152 63.1057C139.242 62.5132 138.143 61.623 138.143 60.1016C138.143 58.0511 139.645 56.9707 141.549 56.9707C142.511 56.9786 143.456 57.2177 144.305 57.668V58.9385C143.649 58.5582 142.613 58.0704 141.549 58.0704C140.237 58.0704 139.369 58.8751 139.369 59.9996C139.369 60.9918 140.215 61.6064 141.039 62.1549L142.795 63.3179C143.685 63.9105 145 64.842 145 66.5122C144.994 68.4497 143.41 69.6624 141.232 69.6624Z"
						fill="currentColor"
					/>
				</svg>
			</div>
		</div>
		<div class="flex gap-5">
			<img
				class="size-20 object-contain"
				alt=""
				src="/img/footer/logo_fablab.png"
			/>
			<img
				class="size-20 object-contain"
				alt=""
				src="/img/footer/logo_3deras.png"
			/>
		</div>
	</div>
	<div>
		<p class="hidden text-center text-neutral-300 md:block">
			Copyright © TXM Methods - 2019-2029
		</p>
	</div>
</footer>
<!-- <Modal bind:this={modal} outerClass="w-2/3 items-center ">
	<div class="flex flex-col p-10 w-full h-full modal gap-4">
		<div>
			<h1 class="text-3xl">Relate seu Problema</h1>
			<h2 class="text-xl">Assim que possível verificaremos a melhor forma de resolvê-lo.</h2>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Em qual página da plataforma você estava quando o problema ocorreu?</span
			>
			<input class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Qual foi o problema (o que você estava fazendo e o que não funcionou)?</span
			>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg">Possui algo a mais para relatar?</span>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex justify-end gap-4">
			<button class="button" on:click={modal.close}>Cancelar</button>
			<button class="button">Enviar</button>
		</div>
	</div>
</Modal> -->
